import { COOKIE } from '~/constants/storedPropertiesNames';

export default defineNuxtRouteMiddleware((to) => {
  const lang = to.query.setLang as string;

  if ('setLang' in to.query && lang) {
    const activeLanguageCookie = useCookie(COOKIE.activeLanguage);

    activeLanguageCookie.value = lang;
    const query = { ...to.query };

    delete query['setLang'];

    return navigateTo({
      path: to.path,
      query,
   });
  }
});